.news {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.news h1 {
  font-size: 3rem;
}

.newscard {
  margin-top: 3rem;
  display: grid;
  justify-content: space-between;
}

.news-card {
  width: 100%;
  text-align: start;
  margin-top: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem 1rem 1rem;
}

.news-image {
  height: 300px;
  overflow: hidden;
  border-radius: 7px;
}

.news-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.news-image:hover img {
  transform: scale(1.3);
}

.news-card h4 {
  font-size: 1.2rem;
  padding: 1rem 0 1rem 0;
}

.news-card svg{
  font-size: 1.5rem;
}

.news-card p#read-more{
  font-weight: 600;
}

.news-card p{
  font-size: 1rem;
  font-weight: 300;
  padding-bottom: 10px;
  text-align: justify;
}

@media screen and (max-width: 850px) {
  .news {
    margin: 4rem 2rem;
  }

  .newscard {
    flex-direction: column;
  }

  .news-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
