.application-details {
  margin: 2rem 10%;
}

.application-details h1 {
  font-size: 3rem;
}

.first-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.first-des-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.application-des-text {
  width: 45%;
  font-size: 1rem;
  text-align: start;
  line-height: 30px;
  letter-spacing: 0.1px;
  word-spacing: 3px;
}

.application-des-text p{
  text-align: justify;
  text-justify: distribute;
  word-spacing: -2px;
}

.application-des-text h2 {
  padding-bottom: 2rem;
}

.application-img {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.application-img img {
  width: 49%;
  height: 350px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 65px -18px rgba(0, 0, 0, 0.19);
}

.application-img img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}

@media screen and (max-width: 850px) {
  .application-details {
    margin: 4rem 2rem;
  }

  .first-des {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2.5rem;
  }

  .first-des-reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 4.5rem;
  }

  .application-des-text {
    width: 100%;
  }

  .application-img {
    width: 100%;
    margin: 1.8rem 0;
  }

  .application-img img {
    height: 250px;
  }
}
