.about-short-main{
    margin: 2rem 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.about-sort-left-right{
    display: grid;
    grid-template-columns: .25fr 2fr;
    row-gap: 70px;
    column-gap: 20px;
    width: 100%;
}

.about-sort-left-right p{
    padding-bottom: 1rem;
}

.about-short-button{
    margin-top: 2rem;
    padding: 15px 15px 15px 15px;
    background: #476d5d;
    color: #e3be4a;
    border-radius: 30px;
    width: 150px;
    text-align: center;
}

.about-short h4{
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: start;
    font-weight: 400;
    color: #476d5d;
    padding-bottom: 2rem;
}

span{
    font-size: 2.5rem;
    color: #e3be4a;
    padding-top: 1rem;
}

.about-short {
    width: 45%;
    text-align: justify;
    font-size: 1rem;
    line-height: 30px;
    letter-spacing: 0.1px;
    word-spacing: 3px;
  }

  .about-short h1{
    font-size: 3rem;
    text-align: end;
    color: #828282;
    padding-top: 1rem;
  }

  .about-short-img {
    position: relative;
    width: 45%;
    height: auto;
    display: flex;
    justify-content: space-between;
    z-index: -99;
  }

  .about-short-img-other{
    padding-top: 2rem;
    position: relative;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
  }

  .about-short-img-other img{
    border-radius: 0px 100px 0px 20px;
  }
  
  .about-short-img img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 0px 20px 0px 100px;
  }

@media screen and (max-width: 850px) {
    
    .about-short-main {
        margin: 4rem 2rem;
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .about-short {
        width: 100%;
    }
  
    .about-short-img {
        width: 100%;
        margin: 1.8rem 0;
    }
    
    .about-short-img img {
        height: 250px;
    }

    .about-short-img-other{
        width:100%;
    }

}