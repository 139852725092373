.hero {
  width: 100%;
  height: 60vh;
  position: relative;
  /* background-image: url("../assets/product&services/coaltech-product-and-service.jpg"); */
  background-image: url("../assets//aboutus/coaltech-overview.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-text .parah-2{
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: 'Sacramento', cursive;
  font-size: 2.5rem;
  font-weight: 200;
}

.hero-mid {
  width: 100%;
  height: 40vh;
  position: relative;
}

.hero-mid h1 {
  padding-top: 4rem !important;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(40%);
}

.hero-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.hero-text h1 {
  font-size: 2.5rem;
  font-weight: 300;
  background: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text p {
  font-size: 1.1rem;
  color: #fff;
  padding: 0.5rem 15rem 1rem 15rem;
  line-height: 30px;
  text-align: center;
}

.hero-text .show {
  background-color: #fff;
  text-decoration: none;
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: #000;
}

.hero-text .hide {
  display: none;
}

@media screen and (max-width:1100px) {
  .hero{
    height: 115vh;
  }
  .hero-text p {
    font-size: 1.1rem;
    padding: 0 1.5rem 2rem 1.5rem ;
  }
}

@media screen and (max-width: 555px) {
  .hero{
    height: 135vh;
  }

  .hero-text h1 {
    padding: 10px 20px;
  }

  .hero-text p {
    font-size: 1.1rem;
    padding: 0 1.5rem 2rem 1.5rem ;
  }

  .hero-text .show {
    padding: 0.6rem 1.1rem;
    border-radius: 6px;
    font-size: 1.1rem;
  }
}



