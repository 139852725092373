.top-to-btm{
    position: relative;
}

.icon-position{
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 999;
}

.icon-style{
  background-color: #e3be4a;
  font-size: 3.2rem;
  color: #ffffff;
  padding: 13px;
  cursor: pointer;
  border-radius: 50%;
  animation: movebtn 1s ease-out ;
  transition:  all .5s ease-out;
}

.icon-style:hover{
  background-color: #516b52;
  opacity: 1;
}

@keyframes movebtn {
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}  