.NavbarItems {
  background-color: #476D5D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 80px;
  border-radius: 0px;
  position: fixed;
  /* top: 500px; */
  left: 50%;
  transform: translate(-50%);
  z-index: 9999;
}

.information-section{
  color: #fff;
  display: flex;
  background-color: #e3be4a;
  height: 20px;
  overflow: hidden;
}

.information-section i{
  color: #fff;
  margin-left: 2rem;
  margin-top: 4px;
  font-size: .9rem;
  vertical-align: text-top;
  align-items: flex-end;
}

.nav-image-menu{
  visibility: hidden;
}

.NavInfoArea{
  background: #fff;
  width: 100%;
  height: 30px;
}

.NavInfoArea p{
  padding: 4px;
}

.NavbarLogo {
  color: #222222;
  font-size: 2rem;
  cursor: pointer;
}

.nav-image{
  width: 11rem;
  height: 4.5rem;
}

.menu-icons {
  display: none;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}

.nav-links {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 200;
  padding: 0.7rem 0.2rem;
  white-space: nowrap;
}

.nav-links-btn{
  text-decoration: none;
  color: #476D5D;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.7rem 1rem;
  background-color: #e3be4a;
  border-radius: 20px;
  white-space: nowrap;
}

.nav-links:hover {
  color: #e3be4a;
  transition: all 0.4s ease-in-out;
}

@media screen and (max-width:1200px) {
  .nav-image{
    width: 10.5rem;
    height: 4.5rem;
  }
}

@media screen and (max-width: 1150px) {
  .NavbarItems {
    z-index: 99;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #476D5D;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: stretch;
    padding: 80px 0 80px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem;
  }


  .nav-links:hover {
    color: #e3be4a;
    transition: all 0.2s ease-in-out;
  }
  .menu-icons {
    display: block;
    cursor: pointer;
    color: #fff;
  }

  .menu-icons i {
    font-size: 1.5rem;
  }
}
