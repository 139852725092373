.image-slider{
    width: 100%;
    height: 100vh;
}

.Carousel .thumb {
  display: none;
}

.slider-img {
    width:100%;
    height:100vh;
    object-fit: cover;
    filter: brightness(40%);
}

@keyframes zoomIn {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(1.1);
  }
}

.image-slider-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .image-slider-text h1 {
    font-size: 3.2rem;
    font-weight: 900;
    position: relative;
    background: #e3be4a;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: 4s anim-lineUp ease-out infinite;
  }

  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  
  .image-slider-text p {
    font-size: 2.3rem;
    font-weight: 200;
    position: relative;
    color: #fff;
    padding: 0 0 0 0;
    animation: 4s anim-lineUp ease-out infinite;
  }
  
  .image-slider-text .show {
    background-color: #fff;
    text-decoration: none;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #000;
  }

  .image-slider-arrow{
    position: absolute;
    bottom: 50px;
    right: 50px;
    display: flex;
    z-index: 10;
  }

  .left-arrow{
    width: 50px;
    height: 50px;
    color: #fff;
    cursor: pointer;
    background: #000d1a;
    border-radius: 50px;
    padding: 10px;
    user-select: none;
    transition: 0.3s;
    margin-right: 1rem;
  }

  .right-arrow{
    width: 50px;
    height: 50px;
    color: #fff;
    cursor: pointer;
    background: #000d1a;
    border-radius: 50px;
    padding: 10px;
    user-select: none;
    transition: 0.3s;
  }
  
  .image-slider-text .hide {
    display: none;
  }

  @media screen and (max-width: 555px) {

    .image-slider-text h1 {
      padding: 10px 20px;
    }

    .image-slider-text p {
      font-size: 2.3rem;
      padding: 0 0 2rem 0;
    }
  
    .image-slider-text .show {
      padding: 0.6rem 1.1rem;
      border-radius: 6px;
      font-size: 1.1rem;
    }

  }