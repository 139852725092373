@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Montserrat:ital,wght@0,400;1,700&family=Poppins:wght@100;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Lato", sans-serif; */
  /* font-family: 'Arimo', sans-serif; */
  font-family: 'Poppins', sans-serif;
  word-spacing: 2px;
}
.App {
  font-family: sans-serif;
  text-align: center;
  word-spacing: 2px;
}

a{
  text-decoration: none;
  text-decoration: none;
  color: #e3be4a;
  font-weight: 600;
}

a:hover{
  color: #476D5D
}
