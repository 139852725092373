.chat-top-to-btm{
    position: relative;
}

.chat-icon-position{
  position: fixed;
  bottom: 400px;
  right: 0;
  z-index: 2;
}

.chat-icon-style{
  background-color: #67D449;
  font-size: 4rem;
  color: #ffffff;
  padding: 13px;
  cursor: pointer;
  animation: movebtn 1s ease-out ;
  transition:  all .5s ease-out;
}

.chat-icon-style:hover{
  background-color: #516b52;
  opacity: 1;
}

@keyframes movebtn {
  0%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(-10px);
  }
}  