.contact{
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.contact-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
}

.contact-heading h2{
    color: #000000;
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 2px;
    position: relative;
}

.contact-container{
    max-width: 1170px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
}

.contact-container-map{
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
}

.row-contact{
    display: flex;
    flex-wrap: wrap;
}

.column-contact{
    flex: 0 0 auto;
    width: 50%;
}

.contact-widget{
    overflow: hidden;
    padding-left: 10px;
}

.myfile{
    border: none;
    height: 25px;
    width: 50%;
}

.contact-widget-item{
    justify-content: start;
    overflow: hidden;
    margin-bottom: 40px;
}

.icon{
    height: 60px;
    width: 60px;
    background: #697d6a;
    border-radius: 50%;
    line-height: 60px;
    text-align: center;
    margin-right: 30px;
    float: left;
}

.icon i{
    font-size: 24px;
    color: #e3be4a;
    position: relative;
    top: 3px;
}

.text{
    overflow: hidden;
}

.text h5{
    text-align: start;
    color: #111;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 6px;
}

.text p{
    text-align: start;
    font-size: 16px;
    color: #111;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 15px 0;
}

.contact-form{
    text-align: center;
    margin-bottom: 40px;
}

form{
    display: block;
    margin-top: 0em;
}

form input{
    height: 46px;
    width: 100%;
    padding-left: 20px;
    font-size: 15px;
    color: #111;
    border: 1px solid #697d6a;
    margin-bottom: 20px;
    border-radius: 4px;
    transition: all , .3s;
}

form select{
    height: 46px;
    width: 100%;
    padding-left: 20px;
    font-size: 15px;
    color: #111;
    border: 1px solid #697d6a;
    margin-bottom: 20px;
    border-radius: 4px;
    transition: all , .3s;
}

.form-field-topic{
    font-size: 13px;
    text-align: start;
    padding: 0 10px 10px ;
}

.red-star{
    font-size: 13px;
    color: red;
}

form textarea{
    height: 110px;
    width: 100%;
    padding-left: 20px;
    font-size: 15px;
    color: #111;
    border: 1px solid #697d6a;
    resize: none;
    margin-bottom: 9px;
    border-radius: 4px;
    transition: all , .3s;
}

form button{
    width: 50%;
    cursor: pointer;
    color: #e3be4a;
}

form button:hover{
    color: #697d6a;
}

.hr-contact{
    width: 50vh;
}

.site-btn{
    border: none;
    display: inline-block;
    background: #697d6a;
    font-size: 14px;
    color: #e3be4a;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 12px 40px 10px;
}

.site-btn:hover{
    background: #e3be4a;
    color: #697d6a;
}

.map-column{
    flex: 0 0 auto;
    width: 100%;
}

.contact-map iframe{
    border-radius: 10px;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .contact-container{
        max-width: 960px;
    }
}

@media screen and (max-width: 990px) {
    .contact-container{
        max-width: 720px;
    }
}

@media screen and (max-width: 768px) {
    .contact-container{
        max-width: 540px;
    }
    .column-contact{
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .contact-heading{
        padding-right: 0;
    }

    .contact-heading h2{
        font-size: 22px;
    }
}



