.application {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.application h1 {
  font-size: 3rem;
}

.applicationcard {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 70px;
  column-gap: 60px;
  width: 100%;
}

.application-card {
  width: 100%;
  position: relative;
  text-align: start;
  border-radius: 7px;
  cursor: pointer;
}

.application-image {
  height: 300px;
  overflow: hidden;
  display: block;
  border-radius: 10px;
}

.application-card .overlay {
  opacity: 1;
}

.overlay:hover{
  background-color: #476d5d82;
}

.application h2{
  font-size: 2rem;
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 10px;
  transition: .5s ease;
}

.application-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
  filter: brightness(60%);
}

.application-image:hover img {
  transform: scale(1.3);
}

.application-card h4 {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.2rem;
  color: #ffffff;
  padding: 1rem 0 0rem 0;
}

.application-card svg{
  font-size: 1.5rem;
}

.application-card p#read-more{
  font-weight: 600;
}

.application-card p{
  font-size: 1rem;
  font-weight: 300;
  padding-bottom: 10px;
  text-align: justify;
}

@media screen and (max-width:1400px){
  .applicationcard {
    grid-template-columns: 1fr 1fr;
  }

  .application-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 850px) {
  .application {
    margin: 4rem 2rem;
  }

  .applicationcard {
    grid-template-columns: 1fr;
  }

  .application-card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
