.manufacturing-main{
    margin: 2rem 10rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.manufacturing-right p{
    font-size: 1rem;
    text-align: justify;
}

.manufacturing-left p{
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 2rem 1rem 0 2rem;
    margin: 10px;
}

.manufacturing-left svg{
    width: 3rem;
    height: 3rem;
    vertical-align: top;
    color: #476D5D;
}

.manufacturing-right h1{
    padding:0 0 2rem 0;
    text-align: start;
}

@media screen and (max-width: 1000px) {
    .manufacturing-main{
        margin: 2rem 2rem;
    }
    .manufacturing-main{
        grid-template-columns: 1fr;
    }
}