.footer {
    padding: 2rem 8rem;
    background: #484848;
    color: #fff;
    background-image: url("../assets/world-map-bg.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.footer-top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-top p{
    padding-top: 0.5rem;
}

i#icon-contatc-footer{
    font-size: 1rem;
    padding-right: .5rem;
}

i#icon-contatc-footer:hover{
    color: #fff;
}

a#icon-contatc-footer{
    text-decoration: none;
    color: #fff;
}

a#icon-contatc-footer:hover{
    color: #e3be4a;
    transition: all 0.4s ease-in-out;
}

.footer-final {
    padding-top: 3rem;
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}
  
.footer-top i {
    color: #fff;
    font-size: 2rem;
    margin-left: 2rem;
    margin-left: 1rem;
}

.footer-final i {
    color: #fff;
    font-size: 2rem;
    margin-left: 2rem;
    margin-left: 1rem;
}

.footer-final a{
    text-decoration: none;
    color: #d0d2d3;
    font-weight: 600;
}
  
.footer-top i:hover {
    color: #e3be4a;
}

.footer-final i:hover {
    color: #e3be4a;
}

.footer-bottom {
    padding-top: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-bottom div{
    display: flex;
    flex-direction: column;
}

.footer-bottom h4{
    font-size: 1.2rem;
    padding: 1rem 0 0.8rem 0;
}

.footer-bottom p{
    font-size: 1rem;
}

.footer-bottom a{
    text-decoration: none;
    color: #fff;
    padding-bottom: 0.4rem;
    font-size: 1rem;
}

.footer-bottom a:hover{
    color: #e3be4a;
    transition: all 0.4s ease-in-out;
}

@media screen and (max-width:850px) {
    .footer{
        padding: 4rem 2rem;
    }

    .footer i{
        margin: 1rem 1rem 0 0;
    }

    .footer-bottom div{
        width: 50%;
    }

    .footer-final p {
        padding-top: 1rem;
    }
}
  