.team-column{
    float: left;
    width: 25%;
    margin-bottom: 30px;
    padding: 0 8px;
}

.team-row{
    padding: 30px 10px 10px 10px;
}

@media screen and (max-width : 1100px) {
    .team-column{
        width: 50%;
    }
}
  
@media screen and (max-width: 850px) {
    .team-column {
        width: 100%;
        display: block;
    }
}

.team-image{
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    border: 2px dotted #516b52;
    padding: 5px;
    margin-bottom: 15px;
}

.tema-header{
    padding-top: 2rem;
    font-size: 2.5rem;
    font-weight: 900;
    color: #000000bc;
}

.team-container {
    padding: 0 16px;
}

.team-container::after, .team-row::after {
    content: "";
    clear: both;
    display: table;
}

.team-title {
    color: #516b52;
    font-weight: 300;
    font-size: 1rem;
    padding: 10px;
}

.team-member-email{
    font-size: 1rem;
    font-weight: 300;
}

.team-member-name{
    font-size: 1.5rem;
    font-weight: 500;
}

.team-button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.team-button:hover {
    background-color: #555;
}