.hero-one-main{
    padding: 0 0 0 6rem;
}

.hero-one-main::after {
    content: "";
    clear: both;
    display: table;
  }

.hero-one-column{
    float: left;
    width: 30%;
    padding: 5px;
}

.hero-one-button{
    margin-top: 2rem;
    padding: 15px 15px 15px 15px;
    background: #476d5d;
    color: #e3be4a;
    border-radius: 30px;
    width: 150px;
    text-align: center;
}

.hero-one-column-1{
    float: left;
    width: 70%;
    padding: 5px;
}

.hero-one-text{
    font-size: 1.5rem;
    font-weight: 200;
    padding: 0rem 2rem 0 0;
    text-align: left;
    
}

@media screen and (max-width: 850px) {
    .hero-one-main{
        padding: 2rem 2rem 0 2rem;
    }
    .hero-one-column {
      width: 100%;
    }
    .hero-one-column-1 {
      width: 100%;
    }
    .hero-one-text{
      padding: 0;
    }
  }